import { Injectable } from '@angular/core';
import { clarity } from 'clarity-js';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ClarityJsService {
  constructor() {
    clarity.start({
      projectId: environment.clarityProjectId,
      content: true,
      upload: this.getClarityEndpoint(),
      track: environment.production,
    });
  }

  /** Call to accept clarity cookies - otherwise, clarity will run in "cookie less" mode */
  consent() {
    clarity.consent();
  }

  private getClarityEndpoint(): string {
    const clarityDotServers = 'abcdefghijklmnopqrstuvwxyz';
    const selectedServer = clarityDotServers[Math.floor(Math.random() * clarityDotServers.length)];
    return `https://${selectedServer}.clarity.ms/collect`;
  }
}
