import {Component, OnInit} from '@angular/core';
import {IonicModule, ToastController} from '@ionic/angular';
import {AsyncPipe, JsonPipe, NgForOf, NgIf} from '@angular/common';
import {JsonDumpService} from './service/JsonDumpInterceptor';
import {IPartner} from '../partner/models/partner.model';
import {ICustomer} from '../customer/models/customer.model';
import {ParseToDebugMenu} from './service/ParseToDebugMenu';
import {MicrosoftTenantService} from '../microsoft/services/microsoft-tenants/microsoft-tenants.service';
import {IMicrosoftTenant, IMicrosoftTenantStatus} from '../microsoft/models/microsoft-tenant.model';
import {IMicrosoftSubscription} from '../microsoft/models/microsoft-subscriptions.model';
import {PartnerService} from '../partner/services/partner.service';

@Component({
  selector: 'debug-menu',
  standalone: true,
  imports: [
    IonicModule,
    NgIf,
    JsonPipe,
    NgForOf,
    AsyncPipe
  ],
  templateUrl: './debug-menu.component.html',
  styleUrl: './debug-menu.component.scss'
})
export class DebugMenuComponent implements OnInit{
  selectedSegment: string = 'moreInfo';
  currentCustomer: ICustomer;
  currentPartner: IPartner;
  customerStatus: IMicrosoftTenantStatus[];
  currentCustomerExtraInfo: IMicrosoftTenant;
  customersSubscriptions: IMicrosoftSubscription[];
  showing: IMicrosoftSubscription[];
  tryingToRetry: boolean = false;
  gettingData: boolean = false;
  public jsonDump: any;

  constructor(
    private jsonDumpService: JsonDumpService,
    private ptdm: ParseToDebugMenu,
    private microapi: MicrosoftTenantService,
    private partapi: PartnerService,
    private toastController: ToastController) {
  }

  public async onSegmentChange(event): Promise<void> {
    this.selectedSegment = event.detail.value;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.presentPopover('Copied to clipboard');
  }

  async presentPopover(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1000,
      position: 'bottom',
    });
    await toast.present();
  }

  ngOnInit() {
    // Get the stored JSON data from the service
    this.jsonDumpService.jsonData$.subscribe(data => {
      this.jsonDump = data;
      this.customersSubscriptions = data;
      this.showing = data;
    });

    this.ptdm.getCustomer().subscribe(data => {
      this.gettingData = true;
      this.currentCustomer = data;
      if (data !== null) {
        this.microapi.getCustomerAttachedMicrosoftTenant(data.id).subscribe(tenant => {
          this.currentCustomerExtraInfo = tenant;
        });
        this.partapi.getPartner(data.partnerId).subscribe(partner => {
          this.currentPartner = partner;
        });
        this.microapi.getMicrosoftTenantStatus(data.id, data.partnerId).subscribe(status => {
          this.customerStatus = status;
          this.gettingData = false;
        });
      }
    });

    this.ptdm.getPartner().subscribe(data => {
      this.currentPartner = data;
    });
  }

  retryApplicationConsent(){
    this.tryingToRetry = true;
    this.gettingData = true;
    this.microapi.retryApplicationConsent(this.currentCustomer.id).subscribe(succes => {
      if (succes){
        this.presentPopover('Application consent retried successfully');
        this.microapi.getMicrosoftTenantStatus(this.currentCustomer.id, this.currentCustomer.partnerId).subscribe(status => {
          this.customerStatus = status;
        });
      } else {
        this.presentPopover('Application consent retried failed');
      }
      this.tryingToRetry = false;
      this.gettingData = false;
    });
  }

  handleInput($event: any) {
    const query = $event.target.value.toLowerCase();
    this.showing = this.customersSubscriptions.filter(sub => {
      return sub.name.toLowerCase().includes(query);
    });
  }
}
