<ion-segment [value]="selectedSegment" (ionChange)="onSegmentChange($event)">
  <ion-segment-button value="moreInfo">
    <ion-label>Information</ion-label>
  </ion-segment-button>
  <ion-segment-button value="json">
    <ion-label>JSON DUMP</ion-label>
  </ion-segment-button>
  <ion-segment-button value="consent">
    <ion-label>MS Consent</ion-label>
  </ion-segment-button>
</ion-segment>


  <ng-container *ngIf="selectedSegment === 'json'">
    <ion-row>
      <ion-col>
        <pre>{{ jsonDump | json}}</pre>
      </ion-col>
    </ion-row>
  </ng-container>

  <ng-container *ngIf="selectedSegment === 'moreInfo'">
    @if (currentCustomer){
      <ion-row>
        <ion-col>
          <h3>Current Partner:</h3>
          <p (click)="copyToClipboard((currentPartner?.id ?? 'null'))" class="copyline">Id: {{currentPartner?.id ?? "null"}}</p>
          <p (click)="copyToClipboard((currentPartner?.externalServices['MICROSOFT'] ?? 'null'))" class="copyline">MspId: {{currentPartner?.externalServices['MICROSOFT'] ?? "null"}}</p>
          <h3>Customer Info:</h3>
          <p (click)="copyToClipboard((currentCustomer?.id ?? 'null'))" class="copyline">Id: {{currentCustomer?.id ?? "null"}}</p>
          <p (click)="copyToClipboard((currentCustomer?.externalServices['MICROSOFT'] ?? 'null'))" class="copyline">MsId: {{currentCustomer?.externalServices['MICROSOFT'] ?? "null"}}</p>
          <p (click)="copyToClipboard((currentPartner?.attributes['MicrosoftDefaultCredentials'] ?? 'null'))" class="copyline">Country: {{currentPartner?.attributes['MicrosoftDefaultCredentials'] ?? "null"}}</p>
          <p>Subscriptions:</p>
          <ion-searchbar [debounce]="100" (ionInput)="handleInput($event)"></ion-searchbar>
          <div class="subscriptions">
            <ng-container *ngFor="let sub of showing">
              <p>Name: {{sub.nickname ? sub.nickname : sub.name}}</p>
              <p>Id: {{sub.id}}</p>
              <p>Pro-Id: {{sub.promotionId ? sub.promotionId : 'NO PROMOTION'}}</p>
              <p>Sku: {{sub.sku}}</p>
              <p>Status: {{sub.status}}</p>
              <div class="bottom-line"></div>
            </ng-container>
          </div>
        </ion-col>
      </ion-row>
    } @else {
      <ion-row>
        <ion-col class="ion-text-center">
          <h1>Navigate to a customer</h1>
          <ion-note>To be able too see more information</ion-note>
        </ion-col>
      </ion-row>
    }
  </ng-container>

  <ng-container *ngIf="selectedSegment === 'consent'">
    <ion-row>
      <ion-col>
        @if (gettingData){
            <ion-row class="ion-text-center">
              <ion-col>
                <h1>HOLD ON</h1>
                <ion-note>we are on the job to get the data</ion-note>
              </ion-col>
            </ion-row>
          <ion-row class="ion-text-center">
            <ion-col>
              <ion-spinner color="primary" style="height: 50px; width: 100px;" name="dots"></ion-spinner>
            </ion-col>
          </ion-row>
        } @else {
          <h3>Consent:</h3>
          <div class="consent">
            <ng-container *ngFor="let consent of customerStatus">
              <h3>{{consent.credentialsId}}</h3>
              <p>Exists: {{consent.exists}}</p>
              <p>Has Microsoft CustomerAgreement: {{consent.hasMicrosoftCustomerAgreement}}</p>
              <p>Has Gdap: {{consent.hasGdap}}</p>
              <p>Allowed By Conditional AccessPolicy: {{consent.allowedByConditionalAccessPolicy}}</p>
              <p>Has Application Consent: {{consent.hasApplicationConsent}}</p>
              <p>Has Passed Review: {{consent.hasPassedReview}}</p>
              <div class="bottom-line"></div>
            </ng-container>
          </div>
        }
        <ion-button disabled="{{tryingToRetry || gettingData}}" class="ion-justify-content-center" (click)="retryApplicationConsent()">
          @if(tryingToRetry){
            <ion-spinner name="dots"></ion-spinner>
          } @else {
            Retry Application Consent
          }
        </ion-button>
      </ion-col>
    </ion-row>
  </ng-container>
